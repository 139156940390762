import React from 'react'
import Link from './link'

import './footer.scss'

interface Props {
  author: string
  location: Location
}

const Footer = ({ author, location }: Props) => {
  const homeLink = (location?.pathname === '/' ? '/#home' : '/')
  const navLinks = [
    { label: 'Home', to: homeLink },
    { label: 'Projects', to: '/project/' },
    { label: 'Portfolio', to: 'https://embodied.net' },
    { label: 'People', to: '/people/' },
    { label: 'Courses', to: '/course/' },
    { label: 'Collabs', to: '/collab/' },
  ]
  const notice = new Date().getFullYear() + (author && ', ' + author)
  return (
    <footer id="footer">
      <div className = 'footer-menu'>
        {navLinks.map((link, index) => (
          <span key={index}>
            {index > 0 && '\u00a0|\u00a0'}
            <Link to={link.to}>{link.label}</Link>
          </span>
        ))}
      </div>
      <div className = 'footer-notice'>
        Site &copy; {notice}
      </div>
    </footer>
  )
}

export default Footer

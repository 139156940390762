import React from 'react'
import { Helmet } from 'react-helmet'

import { useSiteMetadata } from '../hooks/use-site-metadata'

interface Props {
  description?: string
  author?: string
  lang?: string
  title?: string
}

const Meta = ({ title, author, description, lang }: Props) => {
  const { siteTitle, siteTitleShort, siteAuthor, siteDescription, siteUrl, twitter } = useSiteMetadata()
  const metaTitle = title ? `${title} | ${siteTitleShort}` : siteTitle
  const metaAuthor = author ?? siteAuthor
  const metaDescription = description ? description : siteDescription

  const metaElements = [
    {
      property: 'og:title',
      content: metaTitle,
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ]

  if (metaAuthor) {
    metaElements.push({
      property: 'author',
      content: metaAuthor,
    })
  }

  if (metaDescription) {
    metaElements.push(
      {
        property: 'description',
        content: metaDescription,
      },
      {
        property: 'og:description',
        content: metaDescription,
      }
    )
  }

  if (siteUrl) {
    metaElements.push({
      property: 'og:url',
      content: siteUrl,
    })
  }

  if (twitter) {
    metaElements.push({
      property: 'twitter:card',
      content: twitter,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={metaElements}
    />
  )
}

Meta.defaultProps = {
  meta: [],
  lang: 'en',
}

export default Meta

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location, 60)

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop
    document.querySelector('#wrapper').scrollTo({
      top: item - mainNavHeight,
      behavior: 'smooth',
    })
  }

  return true
}
import React from 'react'
import { Link } from 'gatsby'

import { motion } from 'framer-motion'

import './banner.scss'

const movContent = {
  hidden: {
    opacity: 0,
    scale: 0.95,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delay: 1.25,
    },
  },
}

const movImage = {
  hidden: {
    scale: 1.125,
    x: 20,
    filter: 'blur(20px)',
  },
  visible: {
    scale: 1,
    x: 0,
    filter: 'blur(0px)',
    transition: {
      duration: 1.25,
      delay: 0.5,
      type: 'easeInOut',
    },
  },
}

const movImageDiv = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.75,
    }
  },
}

const Banner = () => {
  return (
    <section className="banner">
      <motion.div className="image" data-position="right" 
        initial="hidden" animate="visible" variants={movImageDiv}>
        <motion.img src="/images/banner-ripples_1200x600.jpg" alt="Ripples in still water"
          initial="hidden" animate="visible" variants={movImage}/>
      </motion.div>
      <motion.div className="content"
        initial="hidden" animate="visible" variants={movContent}>
        <h1>Embodied Media <br />
          Research Group</h1>
        <ul className="actions special">
          <li><Link to="/#about" className="button next-down text-uppercase">More</Link></li>
        </ul>
      </motion.div>
    </section>
  )
} 

export default Banner

import React from 'react'
import Link from './link'

import './header.scss'

interface Props {
  location: Location
}

const Header = ({ location }: Props) => {
  const homeLink = (location?.pathname === '/' ? '/#home' : '/')
  const navLinks = [
    { label: 'Home', to: homeLink },
    { label: 'Projects', to: '/project/' },
    { label: 'Portfolio', to: 'https://embodied.net' },
    { label: 'People', to: '/people/' },
    { label: 'Courses', to: '/course/' },
    { label: 'Collabs', to: '/collab/' },
  ]
  return (
    <header id="header">

      <div className="logo">
        <Link to={homeLink}><strong>Embodied Media</strong> Research Group</Link>
      </div>

      <div className="logo-alt">
      <Link to={homeLink}><strong>EM</strong> Research Group</Link>
      </div>

      <nav id="nav">
        <ul>
          {navLinks.map((link, index) => (
            <li className={location?.pathname === link.to ? 'current' : ''} key={index}>
              <Link to={link.to}>{link.label}</Link>
            </li>
          ))}
        </ul>
      </nav>

    </header>
  )
}

export default Header

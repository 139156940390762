import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import Banner from '../components/banner'
import Layout from '../components/layout'
import Link from '../components/link'
import Meta from '../components/meta'

const GlobalComponents = {
  Banner: Banner,
  Link: Link,
}

interface Props {
  children: React.ReactNode
  location: Location
  pageContext: unknown
}

const MdxDefault = ({ children, location, pageContext }: Props) => {
  const { title, author, description } = pageContext.frontmatter
  return (
    <Layout author={author} location={location}>
      <Meta title={title} author={author} description={description} />
      <a id="top"></a>
      <section className="main style1">
        <MDXProvider components={GlobalComponents}>
          {children}
        </MDXProvider>
      </section>
    </Layout>
  )
}

export default MdxDefault 
import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

interface Props {
  children: React.ReactNode
  to: string
  activeClassName: string
  partiallyActive: boolean
}

const Link = ({ children, to, activeClassName, partiallyActive, ...otherProps }: Props) => {
  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...otherProps}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...otherProps}>
      {children}
    </a>
  )
}

export default Link

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-google-doc-view-tsx": () => import("./../../../src/components/google-doc-view.tsx" /* webpackChunkName: "component---src-components-google-doc-view-tsx" */),
  "component---src-components-page-view-tsx": () => import("./../../../src/components/page-view.tsx" /* webpackChunkName: "component---src-components-page-view-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-collab-index-mdx": () => import("./../../../src/pages/collab/index.mdx" /* webpackChunkName: "component---src-pages-collab-index-mdx" */),
  "component---src-pages-collab-living-systems-mdx": () => import("./../../../src/pages/collab/living-systems.mdx" /* webpackChunkName: "component---src-pages-collab-living-systems-mdx" */),
  "component---src-pages-course-index-mdx": () => import("./../../../src/pages/course/index.mdx" /* webpackChunkName: "component---src-pages-course-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-people-index-mdx": () => import("./../../../src/pages/people/index.mdx" /* webpackChunkName: "component---src-pages-people-index-mdx" */),
  "component---src-pages-people-ivan-williams-mdx": () => import("./../../../src/pages/people/ivan-williams.mdx" /* webpackChunkName: "component---src-pages-people-ivan-williams-mdx" */),
  "component---src-pages-people-john-crawford-mdx": () => import("./../../../src/pages/people/john-crawford.mdx" /* webpackChunkName: "component---src-pages-people-john-crawford-mdx" */),
  "component---src-pages-people-lisa-naugle-mdx": () => import("./../../../src/pages/people/lisa-naugle.mdx" /* webpackChunkName: "component---src-pages-people-lisa-naugle-mdx" */),
  "component---src-pages-project-index-mdx": () => import("./../../../src/pages/project/index.mdx" /* webpackChunkName: "component---src-pages-project-index-mdx" */)
}


import React from 'react'

import './nav.scss'

const NavSecondary = () => {
  const navLinks = [
    { label: 'About EMRG', to: '/#about' },
    { label: 'Current Work', to: '/#work' },
    // { label: 'Prof. Crawford', to: 'https://jc.embodied.net' },
  ]
  return (
    <>
      {navLinks.map((link, index) => (
        <div className="col-2 col-4-medium col-6-small" key={index}>
            <a href={link.to} className="button fit small">{link.label}</a>
        </div>
      ))}
    </>
  )
}

export default NavSecondary

import React from 'react'

import Footer from './footer'
import Header from './header'
import { useSiteMetadata } from '../hooks/use-site-metadata'

import { motion } from 'framer-motion'

import './layout.scss'

const movReveal = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
}

interface Props {
  children?: React.ReactNode
  author?: string
  location: Location
}

const Layout = ({ children, author, location }: Props) => {
  const { siteAuthor } = useSiteMetadata()
  const metaAuthor = author ?? siteAuthor

  return (
    <>
      <Header location={location} />
      <motion.div id="wrapper" initial="hidden" animate="visible" variants={movReveal}>
        {children}
        <Footer author={metaAuthor} location={location} />
      </motion.div>
    </>
  )
}

export default Layout
